import styled from "styled-components";
import { theme } from "../../../ui/theme";

export const FooterText = styled("div")`
  font-family: ${theme.fonts.ui};
  font-size: 0.875rem;
  color: #899ea3;
  letter-spacing: 1px;
`;

export const Copyright = styled("div")`
  font-family: ${theme.fonts.ui};
  font-size: 0.875rem;
  color: #b3b3b3;
`;

import React from "react";
import Icon, { PossibleIcons } from "../../../ui/Icon";
import { OutlineButton } from "../../../ui/Button";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconName: PossibleIcons;
}

export const IconButton: React.FC<Props> = ({
  iconName,
  children,
  ...props
}) => {
  return (
    <OutlineButton
      style={{ display: "flex", alignItems: "center" }}
      color="gray"
      {...(props as any)}
    >
      <Icon style={{ marginRight: 6 }} name={iconName} />
      {children}
    </OutlineButton>
  );
};

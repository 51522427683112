import React from "react";
import styled from "styled-components";
import { FlexCenter } from "../../../ui/Flex";
import { WhitePotLogo } from "../../../ui/Logo";
import { SmallWrapper } from "../components";
import { Copyright, FooterText } from "./components";
import { IconButton } from "./IconButton";
import { Link } from "gatsby";
import { useGetAndroidReferrer } from "../../../utils/useGetAndroidReferrer";

const Container = styled("div")`
  width: 450px;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 450px) {
    height: 100px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Footer = () => {
  const referrer = useGetAndroidReferrer();
  return (
    <div style={{ backgroundColor: "#333333", paddingBottom: 60 }}>
      <SmallWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 30,
            marginBottom: 40,
          }}
        >
          <div style={{ marginRight: 24 }}>
            <a
              href={`https://play.google.com/store/apps/details?id=com.awad.saffron${referrer}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton iconName="playstore">Get the Android App</IconButton>
            </a>
          </div>
          <a
            href="https://itunes.apple.com/us/app/saffron-your-digital-cookbook/id1438683531"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton iconName="apple">Get the iOS App</IconButton>
          </a>
        </div>
        <div
          style={{
            width: 300,
            margin: "auto",
            height: 1,
            backgroundColor: "#444444",
          }}
        />
        <FlexCenter>
          <a href="/">
            <WhitePotLogo
              style={{ marginTop: 30 }}
              height="50px"
              width="144px"
            />
          </a>
        </FlexCenter>
        <FlexCenter style={{ marginTop: 30, marginBottom: 40 }}>
          <Container>
            <Link to="/privacy-policy">
              <FooterText>PRIVACY POLICY</FooterText>
            </Link>
            <Link to="/terms-and-conditions">
              <FooterText>TERMS AND CONDITIONS</FooterText>
            </Link>
            <Link to="/contact">
              <FooterText>CONTACT</FooterText>
            </Link>
          </Container>
        </FlexCenter>
        <FlexCenter>
          <Copyright>
            © {new Date().getFullYear()} Awad Development, LLC All Rights
            Reserved.
          </Copyright>
        </FlexCenter>
      </SmallWrapper>
    </div>
  );
};

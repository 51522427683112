import { useState, useEffect, useRef } from "react";
import * as Cookie from "js-cookie";
import qs from "query-string";

export const useGetAndroidReferrer = () => {
  const [referrer, setReferrer] = useState("");
  const isCurrent = useRef(true);

  useEffect(() => {
    const parsed = qs.parse(window.location.search);

    function waitForCookies() {
      const _fbc = Cookie.get("_fbc");
      const _fbp = Cookie.get("_fbp");
      // wait for pixel to load
      if ((!_fbc || !_fbp) && parsed.fbclid && isCurrent.current) {
        setTimeout(waitForCookies, 1000);
        return;
      }

      if (isCurrent.current) {
        setReferrer(`&referrer=${_fbc}||${_fbp}`);
      }
    }

    if (parsed.fbclid) {
      waitForCookies();
    } else {
      if (Object.keys(parsed).length || document.referrer) {
        setReferrer(
          `&referrer=${window.btoa(
            JSON.stringify({
              search: parsed,
              ref: parsed.ref || document.referrer,
            })
          )}`
        );
      }
    }

    return () => {
      isCurrent.current = false;
    };
  }, []);

  return referrer;
};
